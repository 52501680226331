const OUTSIDE_AIRWAY_COLOR = 0xff0000;

export default function checkCenterlineBounds() {
  return (notify, getSceneState) => {
    const { centerline, scene } = getSceneState();

    let isOutsideAirway = false;

    centerline.traverse(b => {
      // ignore stub "branches"
      if (b.points.length > 1 && !b.isEnclosedInAirway()) {
        b.visualize(scene, OUTSIDE_AIRWAY_COLOR);

        isOutsideAirway = true;
      }
    });

    return !isOutsideAirway;
  };
}
